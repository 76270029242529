// ----------------------------------------------------------------------
function path(root, sublink) {
    return "".concat(root).concat(sublink);
}
var ROOTS_DASHBOARD = '/dashboard';
// ----------------------------------------------------------------------
export var PATH_AUTH = {
    login: '/login',
};
export var PATH_PAGE = {
    page403: '/403',
};
export var PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    operators: {
        root: path(ROOTS_DASHBOARD, '/operators'),
        edit: path(ROOTS_DASHBOARD, '/operators/edit'),
        view: path(ROOTS_DASHBOARD, '/operators/view'),
    },
    kpis: {
        root: path(ROOTS_DASHBOARD, '/kpis'),
        edit: path(ROOTS_DASHBOARD, '/kpis/edit'),
        view: path(ROOTS_DASHBOARD, '/kpis/view'),
        new: path(ROOTS_DASHBOARD, '/kpis/new'),
    },
    groupPermissions: path(ROOTS_DASHBOARD, '/permissions'),
    calender: path(ROOTS_DASHBOARD, '/calendar'),
    calls: path(ROOTS_DASHBOARD, '/calls'),
    statsClient: {
        root: path(ROOTS_DASHBOARD, '/stats-client'),
        new: path(ROOTS_DASHBOARD, '/stats-client/new'),
        edit: path(ROOTS_DASHBOARD, '/stats-client/edit'),
        view: path(ROOTS_DASHBOARD, '/stats-client/view'),
    },
    statClientResponse: {
        root: path(ROOTS_DASHBOARD, '/stat-client-response'),
        edit: path(ROOTS_DASHBOARD, '/stat-client-response/edit'),
        view: path(ROOTS_DASHBOARD, '/stat-client-response/view'),
        new: path(ROOTS_DASHBOARD, '/stat-client-response/new'),
    },
    todos: {
        root: path(ROOTS_DASHBOARD, '/todo-list'),
        view: function (id) { return path(ROOTS_DASHBOARD, "/todo-list/view/".concat(id)); },
    },
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    Notifications: path(ROOTS_DASHBOARD, '/notifications'),
    offices: {
        root: path(ROOTS_DASHBOARD, '/offices'),
        edit: function (id) { return path(ROOTS_DASHBOARD, "/offices/edit/".concat(id)); },
    },
    notes: {
        root: path(ROOTS_DASHBOARD, '/notes'),
        edit: function (id) { return path(ROOTS_DASHBOARD, "/notes/edit/".concat(id)); },
        view: function (id) { return path(ROOTS_DASHBOARD, "/notes/view/".concat(id)); },
        new: path(ROOTS_DASHBOARD, '/notes/new'),
    },
    finances: {
        root: path(ROOTS_DASHBOARD, '/finances'),
        expensesList: path(ROOTS_DASHBOARD, '/finances/expenses'),
        expenseNew: path(ROOTS_DASHBOARD, "/finances/expenses/new"),
        expenseEdit: function (id) { return path(ROOTS_DASHBOARD, "/finances/expenses/edit/".concat(id)); },
        expenseView: function (id) { return path(ROOTS_DASHBOARD, "/finances/expenses/view/".concat(id)); },
        codeList: path(ROOTS_DASHBOARD, '/finances/codes'),
        codeEdit: function (id) { return path(ROOTS_DASHBOARD, "/finances/codes/edit/".concat(id)); },
        codeView: function (id) { return path(ROOTS_DASHBOARD, "/finances/codes/view/".concat(id)); },
        generalCashList: path(ROOTS_DASHBOARD, "/finances/general-cash-register"),
        generalCashNew: path(ROOTS_DASHBOARD, "/finances/general-cash-register/new"),
        generalCashEdit: function (id) {
            return path(ROOTS_DASHBOARD, "/finances/general-cash-register/edit/".concat(id));
        },
        generalCashView: function (id) {
            return path(ROOTS_DASHBOARD, "/finances/general-cash-register/view/".concat(id));
        },
    },
    folder: {
        root: path(ROOTS_DASHBOARD, '/folder'),
    },
    session: {
        root: path(ROOTS_DASHBOARD, '/session'),
        calendar: function (id) { return path(ROOTS_DASHBOARD, "/session/user/".concat(id, "/calendar")); },
    },
    configuration: {
        bounusAmount: {
            root: path(ROOTS_DASHBOARD, '/configuration/bonus-amount'),
            new: path(ROOTS_DASHBOARD, '/configuration/bonus-amount/new'),
            edit: function (id) { return path(ROOTS_DASHBOARD, "/configuration/bonus-amount/edit/".concat(id)); },
            view: function (id) { return path(ROOTS_DASHBOARD, "/configuration/bonus-amount/view/".concat(id)); },
        },
        receiptPayment: {
            root: path(ROOTS_DASHBOARD, '/configuration/receipt-payment'),
            new: path(ROOTS_DASHBOARD, '/configuration/receipt-payment/new'),
            view: function (id) { return path(ROOTS_DASHBOARD, "/configuration/receipt-payment/view/".concat(id)); },
            edit: function (id) { return path(ROOTS_DASHBOARD, "/configuration/receipt-payment/edit/".concat(id)); },
            fill: path(ROOTS_DASHBOARD, '/configuration/receipt-payment/fill'),
        },
        paymentNotes: {
            root: path(ROOTS_DASHBOARD, '/configuration/payment-notes'),
            new: path(ROOTS_DASHBOARD, '/configuration/payment-notes/new'),
            view: function (id) { return path(ROOTS_DASHBOARD, "/configuration/payment-notes/view/".concat(id)); },
            edit: function (id) { return path(ROOTS_DASHBOARD, "/configuration/payment-notes/edit/".concat(id)); },
            fill: path(ROOTS_DASHBOARD, '/configuration/payment-notes/fill'),
        },
    },
};
